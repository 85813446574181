import React from "react";
import BgLoading from '../assets/asetgame/loading.png';
import Logo from '../assets/asetgame/logovinfast.png';

const Loading = () => {
  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-6 bg-cover bg-center"
      style={{ backgroundImage: `url(${BgLoading})` }}
    >
      <img className="animate-pulse" src={Logo} alt="" />
    </div>
  );
};

export default Loading;
