import React, { useEffect, useState } from "react";
import Bg from "../assets/newassets/bgnew1.png";
import { useNavigate, useParams } from "react-router-dom";
import VinUni from "../assets/newassets/vinthepoint/pertanyaan1/VINUNI.png";
import VinPearl from "../assets/newassets/vinthepoint/pertanyaan1/VINPEARL.png";
import VinCom from "../assets/newassets/vinthepoint/pertanyaan1/VINCOM.png";
import VinHomes from "../assets/newassets/vinthepoint/pertanyaan1/VINHOMES.png";
import VinFast from "../assets/newassets/vinthepoint/pertanyaan1/VINFAST.png";
import VinBus from "../assets/newassets/vinthepoint/pertanyaan1/VINBUS.png";
import VinSchool from "../assets/newassets/vinthepoint/pertanyaan1/VINSCHOOL.png";
import VinMec from "../assets/newassets/vinthepoint/pertanyaan1/VINMEC.png";
import Logo from "../assets/asetgame/logov.png";
import Swal from "sweetalert2";
import useSound from "use-sound";
import taskSfx from "../assets/sfx/tasksfx.wav";
import wrongSfx from "../assets/sfx/wrongsfx.wav";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Game1 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [playTaskSfx] = useSound(taskSfx);
  const [playWrongSfx] = useSound(wrongSfx);
  const [gameStatus, setGameStatus] = useState(false);
  const [totalGame, setTotalGame] = useState(0);

  const [userId, setUserId] = useState(id || "");

  const baseURL = "https://vinfast.giias2024.mahakreasi.com/api";

  const fetchUserData = async (id) => {
    try {
      const response = await fetch(`${baseURL}/users/${id}`);
      const data = await response.json();
      
      const vin1 = parseInt(data.vin1, 10);
      const vin2 = parseInt(data.vin2, 10);
      const vin3 = parseInt(data.vin3, 10);
      const vin4 = parseInt(data.vin4, 10);

      // Calculate totalGame
      setTotalGame(vin1 + vin2 + vin3 + vin4);

      if (data.vin1 === "1") {
        setGameStatus(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData(userId);
      console.log(gameStatus);
    }
  }, [userId]);

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
    const correctAnswer = "I";
    const isAnswerCorrect = answer === correctAnswer;
    setIsCorrect(isAnswerCorrect);

    const handleAnswer = async (userId) => {
      try {
        const response = await fetch(
          `https://vinfast.giias2024.mahakreasi.com/api/confirm-vingame/${userId}/Game1`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Confirmation response:", data);
      } catch (error) {
        console.error("Failed to confirm vingame:", error);
      }
    };

    if (isAnswerCorrect) {
      playTaskSfx();
      handleAnswer(id);
    } else {
      playWrongSfx();
    }

    setTimeout(() => {
      Swal.fire({
        title: isAnswerCorrect
          ? totalGame === 3
            ? "Selamat! Kamu berhasil menjawab benar semua pertanyaan!"
            : `Selamat! Kamu berhasil menjawab ${totalGame + 1} dari 4 Quiz, ayo lanjutkan bermain!`
          : "Salah! Silahkan coba jawab kembali pertanyaan dengan benar.",
        html: `
          <div class="text-justify font-muli">
            Vingroup adalah perusahaan konglomerasi terbesar di Vietnam yang hadir dalam beberapa sektor seperti VinHomes (Perumahan), VinPearl (Resor & Hotel), VinCom (Mall), VinMec (Layanan Kesehatan), VinSchool & VinUni (Pendidikan), VinSmart (Elektronik), VinEco, dan masih banyak lagi.
          </div>
        `,
        icon: isAnswerCorrect ? "success" : "error",
        confirmButtonText: "OK",
        customClass: {
          content: "text-justify font-muli",
        },
      }).then(() => {
        navigate(`/${id}`);
      });
    }, 600);
  };

  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-2 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <img className="h-24 w-auto" src={Logo} alt="" />
      <div
        className={`p-6 rounded-lg text-white text-center bg-custom-gradient m-5 ${
          gameStatus ? "" : "hidden"
        }`}
      >
        <p className="mb-4 text-lg font-muli-semibold">
          Kamu sudah berhasil menjawab pertanyaan ini dengan benar. Silahkan
          jawab pertanyaan yang lain.
        </p>
        <button
          onClick={() => {
            navigate(`/${userId}`);
          }}
          className="p-4 bg-blue-600 rounded-lg font-muli"
        >
          Kembali ke menu scanner
        </button>
      </div>
      <div
        className={`p-6 rounded-lg text-white text-center bg-custom-gradient m-5 ${
          gameStatus ? "hidden" : ""
        }`}
      >
        <p className="mb-4 text-lg font-muli-semibold">
          Berikut ini manakah yang termasuk bidang bisnis dari Vin group?
        </p>
        <div className="space-y-4 font-muli">
          <div className="flex justify-center space-x-10">
            <button
              onClick={() => handleAnswerClick("A")}
              className={`rounded-lg ${
                selectedAnswer === "A" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "I" &&
                selectedAnswer === "A"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-28 h-auto rounded-lg" src={VinUni} alt="" />
              <p className="text-white">A. VinUni</p>
            </button>
            <button
              onClick={() => handleAnswerClick("B")}
              className={`rounded-lg ${
                selectedAnswer === "B" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "I" &&
                selectedAnswer === "B"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-28 h-auto rounded-lg" src={VinPearl} alt="" />
              <p className="text-white">B. VinPearl</p>
            </button>
          </div>
          <div className="flex justify-center space-x-10">
            <button
              onClick={() => handleAnswerClick("C")}
              className={`rounded-lg ${
                selectedAnswer === "C" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "I" &&
                selectedAnswer === "C"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-28 h-auto rounded-lg" src={VinFast} alt="" />
              <p className="text-white">C. VinFast</p>
            </button>
            <button
              onClick={() => handleAnswerClick("D")}
              className={`rounded-lg ${
                selectedAnswer === "D" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "I" &&
                selectedAnswer === "D"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-28 h-auto rounded-lg" src={VinBus} alt="" />
              <p className="text-white">D. VinBus</p>
            </button>
          </div>
          <div className="flex justify-center space-x-10">
            <button
              onClick={() => handleAnswerClick("E")}
              className={`rounded-lg ${
                selectedAnswer === "E" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "I" &&
                selectedAnswer === "E"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-28 h-auto rounded-lg" src={VinCom} alt="" />
              <p className="text-white">E. VinCom</p>
            </button>
            <button
              onClick={() => handleAnswerClick("F")}
              className={`rounded-lg ${
                selectedAnswer === "F" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "I" &&
                selectedAnswer === "F"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-28 h-auto rounded-lg" src={VinHomes} alt="" />
              <p className="text-white">F. VinHomes</p>
            </button>
          </div>
          <div className="flex justify-center space-x-10">
            <button
              onClick={() => handleAnswerClick("G")}
              className={`rounded-lg ${
                selectedAnswer === "G" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "I" &&
                selectedAnswer === "G"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-28 h-auto rounded-lg" src={VinSchool} alt="" />
              <p className="text-white">G. VinSchool</p>
            </button>
            <button
              onClick={() => handleAnswerClick("H")}
              className={`rounded-lg ${
                selectedAnswer === "H" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "I" &&
                selectedAnswer === "H"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-28 h-auto rounded-lg" src={VinMec} alt="" />
              <p className="text-white">H. VinMec</p>
            </button>
          </div>
          <button
            onClick={() => handleAnswerClick("I")}
            className={`w-fit h-auto px-2 py-1 text-black rounded-lg ${
              selectedAnswer === "I" ? "animate-shrink-expand" : ""
            } ${
              selectedAnswer === "I" && isCorrect
                ? "bg-green-600"
                : selectedAnswer === "I"
                ? "bg-red-600"
                : "bg-white"
            }`}
          >
            <FontAwesomeIcon icon={faCheck} className="w-6 h-6" />
            <p>I. Semua Benar</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Game1;
