import React, { useEffect, useState } from "react";
import Bg from "../assets/newassets/bgnew1.png";
import Logo from "../assets/asetgame/logov.png";
import MiniMap from "../assets/newassets/Map/mapfull.png";
import Line from "../assets/newassets/Map/line.png";
import Game1 from "../assets/newassets/Map/vf51.png";
import Game2 from "../assets/newassets/Map/vf52.png";
import Game3 from "../assets/newassets/Map/vfe341.png";
import Game4 from "../assets/newassets/Map/vfe342.png";
import { useNavigate, useParams } from "react-router-dom";
import { Scanner as QrScanner } from "@yudiel/react-qr-scanner";

const MainMenu = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userName, setUserName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [totalGame, setTotalGame] = useState(0);
  const [vinGame1, setVinGame1] = useState(0);
  const [vinGame2, setVinGame2] = useState(0);
  const [vinGame3, setVinGame3] = useState(0);
  const [vinGame4, setVinGame4] = useState(0);

  const baseURL = "https://vinfast.giias2024.mahakreasi.com/api";

  useEffect(() => {
    if (id) {
      setUserId(id);
    }
  }, [id]);

  useEffect(() => {
    if (userId) {
      setIsLoggedIn(true);
      fetchUserData(userId);
    }
  }, [userId]);

  const fetchUserData = async (userId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseURL}/users/${userId}`);
      const data = await response.json();
      setUserName(data.name || "User");

      const vin1 = parseInt(data.vin1, 10);
      const vin2 = parseInt(data.vin2, 10);
      const vin3 = parseInt(data.vin3, 10);
      const vin4 = parseInt(data.vin4, 10);

      setVinGame1(vin1);
      setVinGame2(vin2);
      setVinGame3(vin3);
      setVinGame4(vin4);

      // Calculate totalGame
      setTotalGame(vin1 + vin2 + vin3 + vin4);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch user data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleScan = (data) => {
    if (data && data.length > 0 && data[0].rawValue) {
      switch (data[0].rawValue) {
        case "1":
          navigate(`/game1/${userId}`);
          break;
        case "2":
          navigate(`/game2/${userId}`);
          break;
        case "3":
          navigate(`/game3/${userId}`);
          break;
        case "4":
          navigate(`/game4/${userId}`);
          break;
        default:
          navigate(`/${userId}`);
          break;
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError(err.message);
  };

  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-6 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <img className="h-44 w-44" src={Logo} alt="" />
      {userName && (
        <div className="px-4 bg-custom-gradient mx-4 flex flex-col space-y-3 rounded-lg py-3">
          <p className="font-muli text-gray-200">
            Hai, {userName}! Selamat datang di Vin the Point quiz!
          </p>
          {totalGame === 0 ? (
            <p className="font-muli text-gray-200">
              Kamu belum menjawab pertanyaan sama sekali.
            </p>
          ) : (
            <div className="space-y-5 relative">
              <p className="font-muli text-gray-200">
                {totalGame === 4
                  ? `Selamat! Kamu berhasil menjawab semua pertanyaan di Vin the Point.`
                  : `Kamu sudah berhasil menjawab ${totalGame}/4 pertanyaan.`}
              </p>
              <div className="relative">
                <img src={MiniMap} alt="" className="w-full" />
                <img
                  src={Line}
                  alt=""
                  className="absolute top-0 left-0 w-full h-full object-cover z-30"
                />
                {vinGame1 === 1 && (
                  <img
                    src={Game1}
                    alt=""
                    className="absolute bottom-[0px] left-0 w-[113px] h-auto z-10 filter drop-shadow-glow"
                  />
                )}
                {vinGame2 === 1 && (
                  <img
                    src={Game2}
                    alt=""
                    className="absolute bottom-[0px] left-[112px] w-[113px] h-auto z-10 filter drop-shadow-glow"
                  />
                )}
                {vinGame3 === 1 && (
                  <img
                    src={Game3}
                    alt=""
                    className="absolute bottom-0 right-0 w-[113px] h-auto z-10 filter drop-shadow-glow"
                  />
                )}
                {vinGame4 === 1 && (
                  <img
                    src={Game4}
                    alt=""
                    className="absolute top-[0px] right-0 w-[95px] h-auto z-10 filter drop-shadow-glow"
                  />
                )}
              </div>
            </div>
          )}
          {totalGame === 4 && (
            <button
              onClick={() => {
                window.location.href =
                  "https://vinfast.giias2024.mahakreasi.com/";
              }}
              className="p-4 text-gray-200 bg-blue-600 rounded-lg font-muli"
            >
              Kembali ke Digital Stamp VinFast.
            </button>
          )}
          {totalGame !== 4 && (
            <button
              onClick={() => {
                navigate(`/home/${userId}`);
              }}
              className="p-4 text-gray-200 bg-blue-600 rounded-lg font-muli"
            >
              Scan QR Vin the Point
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default MainMenu;
