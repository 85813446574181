import React, { useState } from "react";
import Bg from "../assets/newassets/bgnew1.png";
import Logo from "../assets/asetgame/logov.png";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import startsfx from "../assets/sfx/startsfx.wav";
import "../index.css";

const Home4 = () => {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  const [play] = useSound(startsfx, { volume: 0.5 });

  const handleClick = () => {
    play();
    setIsClicked(true);
    setTimeout(() => {
      navigate("/game4");
    }, 600);
  };

  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-2 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <img className="h-44 w-44" src={Logo} alt="" />
      <button
        onClick={handleClick}
        className={`py-3 px-20 text-2xl font-muli-semibold bg-white outline-lightblue rounded-full transition-transform duration-200 ${
          isClicked ? "animate-shrink-expand" : ""
        }`}
      >
        START
      </button>
    </div>
  );
};

export default Home4;
