import React, { useEffect, useState } from "react";
import Bg from "../assets/newassets/bgnew1.png";
import { useNavigate, useParams } from "react-router-dom";
import Battery from "../assets/newassets/vinthepoint/pertanyaan4/rental.png";
import Us from "../assets/newassets/vinthepoint/pertanyaan4/pasar.png";
import Factory from "../assets/newassets/vinthepoint/pertanyaan4/pabrik.png";
import Vf7 from "../assets/newassets/vinthepoint/pertanyaan4/DESAIN.png";
import Logo from "../assets/asetgame/logov.png";
import Swal from "sweetalert2";
import useSound from "use-sound";
import taskSfx from "../assets/sfx/tasksfx.wav";
import wrongSfx from "../assets/sfx/wrongsfx.wav";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Game4 = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);
  const [playTaskSfx] = useSound(taskSfx);
  const [playWrongSfx] = useSound(wrongSfx);
  const [gameStatus, setGameStatus] = useState(false);
  const [totalGame, setTotalGame] = useState(0);

  const [userId, setUserId] = useState(id || "");

  const baseURL = "https://vinfast.giias2024.mahakreasi.com/api";

  const fetchUserData = async (id) => {
    try {
      const response = await fetch(`${baseURL}/users/${id}`);
      const data = await response.json();

      const vin1 = parseInt(data.vin1, 10);
      const vin2 = parseInt(data.vin2, 10);
      const vin3 = parseInt(data.vin3, 10);
      const vin4 = parseInt(data.vin4, 10);

      // Calculate totalGame
      setTotalGame(vin1 + vin2 + vin3 + vin4);

      if (data.vin4 === "1") {
        setGameStatus(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData(userId);
      console.log(gameStatus);
    }
  }, [userId]);

  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
    const correctAnswer = "E";
    const isAnswerCorrect = answer === correctAnswer;
    setIsCorrect(isAnswerCorrect);

    const handleAnswer = async (userId) => {
      try {
        const response = await fetch(
          `https://vinfast.giias2024.mahakreasi.com/api/confirm-vingame/${userId}/Game4`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("Confirmation response:", data);
      } catch (error) {
        console.error("Failed to confirm vingame:", error);
      }
    };

    if (isAnswerCorrect) {
      playTaskSfx();
      handleAnswer(id);
    } else {
      playWrongSfx();
    }

    setTimeout(() => {
      Swal.fire({
        title: isAnswerCorrect
          ? totalGame === 3
            ? "Selamat! Kamu berhasil menjawab benar semua pertanyaan!"
            : `Selamat! Kamu berhasil menjawab ${
                totalGame + 1
              } dari 4 Quiz, ayo lanjutkan bermain!`
          : "Salah! Silahkan coba jawab kembali pertanyaan dengan benar.",
        html: `
          <div class="text-justify font-muli">
            Design mobil VinFast dibuat oleh designer terkenal dari Itali, dengan design yang ciamik vinfast berhasil mengembangkan sayapnya di pasar amerika dan eropa, kini VinFast memiliki pabrik terbesar di dunia sebesar 355 hektar.
          </div>
        `,
        icon: isAnswerCorrect ? "success" : "error",
        confirmButtonText: "OK",
        customClass: {
          content: "text-justify font-muli",
        },
      }).then(() => {
        navigate(`/${id}`);
      });
    }, 600);
  };

  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-2 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <img className="h-24 w-auto" src={Logo} alt="" />
      <div
        className={`p-6 rounded-lg text-white text-center bg-custom-gradient m-5 ${
          gameStatus ? "" : "hidden"
        }`}
      >
        <p className="mb-4 text-lg font-muli-semibold">
          Kamu sudah berhasil menjawab pertanyaan ini dengan benar. Silahkan
          jawab pertanyaan yang lain.
        </p>
        <button
          onClick={() => {
            navigate(`/${userId}`);
          }}
          className="p-4 bg-blue-600 rounded-lg font-muli"
        >
          Kembali ke menu scanner
        </button>
      </div>
      <div
        className={`p-6 rounded-lg text-white text-center bg-custom-gradient m-5 ${
          gameStatus ? "hidden" : ""
        }`}
      >
        <p className="mb-4 text-lg font-muli-semibold">
          Manakah dari pilihan berikut yang merupakan fakta tentang VinFast?
        </p>
        <div className="space-y-4 font-muli">
          <div className="flex justify-center space-x-5">
            <button
              onClick={() => handleAnswerClick("A")}
              className={`rounded-lg ${
                selectedAnswer === "A" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "E" &&
                selectedAnswer === "A"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-fit h-auto rounded-lg" src={Vf7} alt="" />
              <p className="text-white">
                A. Di Desain oleh Desainer Terkenal dari Italia
              </p>
            </button>
            <button
              onClick={() => handleAnswerClick("B")}
              className={`rounded-lg ${
                selectedAnswer === "B" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "E" &&
                selectedAnswer === "B"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-fit h-auto rounded-lg" src={Us} alt="" />
              <p className="text-white">
                B. Terdaftar di Pasar Amerika dan Eropa
              </p>
            </button>
          </div>
          <div className="flex justify-center space-x-5">
            <button
              onClick={() => handleAnswerClick("C")}
              className={`rounded-lg ${
                selectedAnswer === "C" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "E" &&
                selectedAnswer === "C"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-fit h-auto rounded-lg" src={Factory} alt="" />
              <p className="text-white">C. Pabrik Sebesar 355 Hektar</p>
            </button>
            <button
              onClick={() => handleAnswerClick("D")}
              className={`rounded-lg ${
                selectedAnswer === "D" ? "animate-shrink-expand" : ""
              } ${
                selectedAnswer &&
                selectedAnswer !== "E" &&
                selectedAnswer === "D"
                  ? "bg-red-500"
                  : ""
              }`}
            >
              <img className="w-fit h-auto rounded-lg" src={Battery} alt="" />
              <p className="text-white">D. Pilihan Rental Baterai</p>
            </button>
          </div>
          <button
            onClick={() => handleAnswerClick("E")}
            className={`w-fit h-28 px-4 py-3 text-black rounded-lg ${
              selectedAnswer === "E" ? "animate-shrink-expand" : ""
            } ${
              selectedAnswer === "E" && isCorrect
                ? "bg-green-600"
                : selectedAnswer === "E"
                ? "bg-red-600"
                : "bg-white"
            }`}
          >
            <FontAwesomeIcon icon={faCheck} className="w-6 h-6" />
            <p>E. Semua Benar</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Game4;
