import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingScreen from "./layouts/Loading";
import MainMenu from "./layouts/MainMenu";
import Home from "./layouts/Home";
import Home1 from "./layouts/Home1";
import Home2 from "./layouts/Home2";
import Home3 from "./layouts/Home3";
import Home4 from "./layouts/Home4";
import Game1 from "./layouts/Game1";
import Game2 from "./layouts/Game2";
import Game3 from "./layouts/Game3";
import Game4 from "./layouts/Game4";
import useSound from "use-sound";
import BackgroundMusic from "./assets/sfx/bgmvinfast.mp3"; // Ensure this file exists

function App() {
  const [loading, setLoading] = useState(true);
  const [play, { stop }] = useSound(BackgroundMusic, { volume: 0.5, loop: true });

  useEffect(() => {
    console.log("Component mounted, attempting to play background music");
    play(); // Play background music on component mount

    setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      console.log("Component unmounted, stopping background music");
      stop(); // Stop the music when the component unmounts
    };
  }, [play, stop]);

  return (
    <div className="h-screen flex justify-center align-middle app-background">
      <Router>
        {loading ? (
          <LoadingScreen />
        ) : (
          <Routes>
            <Route path="/" exact element={<MainMenu />} />
            <Route path="/:id" exact element={<MainMenu />} />
            <Route path="/home/:id" exact element={<Home />} />
            <Route path="/home1" exact element={<Home1 />} />
            <Route path="/home2" exact element={<Home2 />} />
            <Route path="/home3" exact element={<Home3 />} />
            <Route path="/home4" exact element={<Home4 />} />
            <Route path="/game1/:id" exact element={<Game1 />} />
            <Route path="/game2/:id" exact element={<Game2 />} />
            <Route path="/game3/:id" exact element={<Game3 />} />
            <Route path="/game4/:id" exact element={<Game4 />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
