import React, { useEffect, useState } from "react";
import Bg from "../assets/newassets/bgnew1.png";
import Logo from "../assets/asetgame/logov.png";
import { useNavigate, useParams } from "react-router-dom";
import { Scanner as QrScanner } from "@yudiel/react-qr-scanner";

const Home = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userName, setUserName] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [totalGame, setTotalGame] = useState(0);

  const baseURL = "https://vinfast.giias2024.mahakreasi.com/api";

  useEffect(() => {
    if (id) {
      setUserId(id);
    }
  }, [id]);

  useEffect(() => {
    if (userId) {
      setIsLoggedIn(true);
      fetchUserData(userId);
    }
  }, [userId]);

  const fetchUserData = async (userId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseURL}/users/${userId}`);
      const data = await response.json();
      setUserName(data.name || "User");

      const vin1 = parseInt(data.vin1, 10);
      const vin2 = parseInt(data.vin2, 10);
      const vin3 = parseInt(data.vin3, 10);
      const vin4 = parseInt(data.vin4, 10);

      // Calculate totalGame
      setTotalGame(vin1 + vin2 + vin3 + vin4);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch user data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleScan = (data) => {
    if (data && data.length > 0 && data[0].rawValue) {
      switch (data[0].rawValue) {
        case "1":
          navigate(`/game1/${userId}`);
          break;
        case "2":
          navigate(`/game2/${userId}`);
          break;
        case "3":
          navigate(`/game3/${userId}`);
          break;
        case "4":
          navigate(`/game4/${userId}`);
          break;
        default:
          navigate(`/${userId}`);
          break;
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setError(err.message);
  };

  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full space-y-6 bg-cover bg-center"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <img className="h-44 w-44" src={Logo} alt="" />
      {isLoggedIn ? (
        <div
          className={`p-2 rounded-lg border-6 border-gradient-blue ${
            totalGame === 4 ? "hidden" : ""
          }`}
        >
          <QrScanner
            delay={300}
            onError={handleError}
            onScan={handleScan}
            style={{ width: "200px" }}
          />
        </div>
      ) : (
        <div className="px-4 bg-custom-gradient mx-4 flex flex-col space-y-3 rounded-lg py-3">
          <p className="font-muli-semibold text-2xl text-center">
            Silahkan login terlebih dahulu sebelum memainkan Vin the Point!
          </p>
          <button
            onClick={() => {
              window.location.href =
                "https://vinfast.giias2024.mahakreasi.com/";
            }}
            className="p-4 text-gray-200 bg-blue-600 rounded-lg font-muli"
          >
            Kembali ke Digital Stamp VinFast.
          </button>
        </div>
      )}
      {isLoading && <p>Loading...</p>}
      {error && <p className="text-red-500">Silahkan refresh page ini!</p>}
    </div>
  );
};

export default Home;
